body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

html,
body {
  padding: 0;
  margin: 0;
}

body {
  overflow: hidden;
  overflow-y: auto;
  user-select: none;
  /* outline: 1px solid blue; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.doubleItem {
  margin-top: -170px !important;
}

.slick-dots {
  position: absolute !important;
  bottom: 190px !important;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots ul {
  padding-left: 0px;
}

.slick-dots li {
  width: 82px !important;
  height: 82px !important;
  margin: 0 9px !important;
}

.slick-dots li img {
  width: 82px;
  height: 82px;
}

/* .slick-dots li img {
  width: 100%;
  height: auto;
} */

.slick-active img {
  border-color: white !important;
  border-width: 2px;
}

*:focus {
  outline: none;
}

.hidden-btn {
  display: block;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 0px;
  text-indent: -9999px;
  left: 1030px;
  /* border: 1px solid red; */
}

@media (min-width: 0px) and (max-width: 1024px) {
  .hidden-btn {
    display: block;
    width: 35px;
    height: 35px;
    position: fixed;
    top: 0px;
    text-indent: -9999px;
    left: auto;
    right: 0px;
  }

  .slick-dots {
    bottom: 80px !important;
  }

  .slick-dots ul {
    padding-left: 0px;
  }

  .slick-dots li {
    width: 50px !important;
    height: 50px !important;
    margin: 0 6px !important;
  }

  .slick-dots li img {
    width: 50px;
    height: 50px;
  }
}

.relly {
  position: relative;
}

/* @ font-face */

/* @font-face {
  font-family: "Inter", sans-serif;
  src: url("assets/fonts/EuropaGroteskSH-Med.woff2") format("woff2"),
    url("assets/fonts/EuropaGroteskSH-Med.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter", sans-serif;
  src: url("assets/fonts/EuropaGroteskSH-Ext.woff2") format("woff2"),
    url("assets/fonts/EuropaGroteskSH-Ext.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter", sans-serif;
  src: url("assets/fonts/EuropaGroteskSH-Reg.woff2") format("woff2"),
    url("assets/fonts/EuropaGroteskSH-Reg.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} */

/* @font-face {
  font-family: "Inter", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Inter:wght@900&display=swap");
  font-weight: bold;
  font-style: normal;
  font-display: block;
} */

/* @font-face {
  font-family: "Inter", sans-serif;
  src: url("assets/fonts/EuropaGroteskSH-DemBol.woff2") format("woff2"),
    url("assets/fonts/EuropaGroteskSH-DemBol.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} */
